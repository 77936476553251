import React from "react";
import { Formik, Form, useField } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";
import "./FormModal.css";
import axios from "axios";
import {BASE_URL} from '../helpers'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

// import axios from "axios";

export default function FormModal() {
  const navigate = useNavigate();
  const MyTextArea = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <textarea className="text-area" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validate = Yup.object({
    fullName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("First Name is Required"),

    email: Yup.string().email("Email is invalid").required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone is required"),
    message: Yup.string()
      // .oneOf([Yup.ref('password'), null], 'Password must match')
      .required("Message is required"),
  });
  return (
    <Formik
      initialValues={{
        fullName: "",
        email: "",
        phone: "",
        message: "",
      }}
      validationSchema={validate}
      onSubmit={(data) => {
        // console.log(data);

        axios({
          method: "post",
          url: `${BASE_URL}/estimate`,
          data: data,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        })
          .then(function (response) {
            //handle success
            toast.success('Data has been send successfully!!', {
              position: "bottom-center",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
              setTimeout(() => {
                navigate(0)
              }, 2000);
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      }}
    >
      {(formik) => (
        <>
          <div className=" text-center ">
            <Form className="col-md-12">
              <TextField label="Full Name" name="fullName" type="text" />

              <TextField label="Phone" name="phone" type="number" />
              <TextField label="Email" name="email" type="email" />
              <MyTextArea
                   style={{
                    backgroundColor: "#f0f0ff",
                    border: "1px solid #d7dce1",
                    borderRadius: "7px",
                    padding: "1px 6px",
                    outline: "none",
                    marginTop:'6px'
                  }}
                label="Message"
                name="message"
                rows="3"
                cols="73"
                // placeholder="Once upon a time there was a princess who lived at the top of a glass hill."
              />

              <button
                className="btn btn-primary mt-2"
                type="submit"
                style={{ marginRight: 20 }}
              >
                Submit
              </button>
              {/* <button className="btn btn-warning mt-3 ml-3" type="reset">
                  Reset
                </button> */}
            </Form>
            <ToastContainer />
          </div>
        </>
      )}
    </Formik>
  );
}
