import React, { useEffect, useState } from "react";
import "./Portfolio.css";
import { Container } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TechContactForm from "../TechContactForm/TechContactForm";
import Footer from "../Footer/Footer";
import HashLoader from "react-spinners/HashLoader";
import mobile from "../assets/mobile.png";
import mobile2 from "../assets/mobile (1).png";
import shelby from "../assets/shelbybrother.png";
import shelby1 from "../assets/shelbybrother1.png";
import sayahat from "../assets/sayahat.png";
import sayahat1 from "../assets/sayahat-1.png";
import Navbar from "../Navbar/Navbar";

const Portfolio = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
    <Navbar />
      {loading ? (
        <div style={{
          backgroundColor:'black',
          height:'100vh'
        }}>

        <HashLoader
          color={`#F37A24`}
          loading={loading}
          size={80}
          style={{
            position: "relative",
            left: "50%",
            top: " 0px",
            zIndex: "10000",
            marginTop: "340px",
            borderImage: "initial",
            width: "30px",
            height: "30px",
            borderRadius: "100%",
            display: "inline-block",
            animation:
            "0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip",
          }}
          />
          </div>
      ) : (
        <>
          <div className="bgImagePortfolio"></div>
          <Container>
            <Row className="portfolio_text" style={{ margin: "0" }}>
              <Col md={6} lg={6} xm={12}>
                <h3 className="text_port">
                  MAKE YOUR <br /> DREAM PROJECT <br /> A REALITY
                </h3>
              </Col>
              <Col md={6} xm={12} lg={6}>
                <img
                  src="https://mmcgbl-1cc8f.kxcdn.com/wp-content/webp-express/webp-images/uploads/2021/06/athelete-2-515x355-1.png.webp"
                  alt=""
                  style={{
                    filter:
                      "brightness(27%) contrast(106%) saturate(199%) blur(0px) hue-rotate(0deg)",
                  }}
                  className="img_port"
                />
              </Col>
            </Row>
            <h3 style={{ textAlign: "center", marginTop: "42px" }}>
              Our Successful Projects
            </h3>
            <p style={{ textAlign: "center" }}>
              We are pleased to report that we have a number of happy customers,
              and serving them improved our competitiveness. Investigate the
              projects right immediately!
            </p>
          </Container>
          <Row>
            <Col
              md={4}
              style={{
                // backgroundImage:
                //   "linear-gradient(182deg,#FFFFFF00 2%,#A9803B 2.2%)",
                backgroundImage:
                  "linear-gradient(182deg, rgba(255, 255, 255, 0) 2%, rgb(0 0 0) 2.2%)",
                height: "450px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              data-aos="fade-up"
            >
              <img
                src={mobile}
                style={{ height: "270px" }}
                alt=""
                className="project_image"
              />
              <img
                src={mobile2}
                style={{ height: "270px" }}
                alt=""
                className="project_image"
              />
            </Col>
            <Col
              md={4}
              style={{
                // backgroundImage:
                //   "linear-gradient(182deg,#FFFFFF00 4.1%,#06C167 4.2%)",
                backgroundImage:
                  "linear-gradient(182deg, rgba(255, 255, 255, 0) 4.1%, rgb(24 18 32) 4.2%)",
                height: "450px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "7px",
              }}
              data-aos="fade-up"
            >
              <img
                src={shelby}
                style={{ height: "270px" }}
                alt=""
                className="project_image"
              />
              <img
                src={shelby1}
                style={{ height: "270px" }}
                alt=""
                className="project_image"
              />
            </Col>
            <Col
              md={4}
              style={{
                // backgroundImage:
                //   "linear-gradient(182deg,#FFFFFF00 6.1%,#A93F76 6.2%)",
                backgroundImage:
                  "linear-gradient(182deg, rgba(255, 255, 255, 0) 6.1%, rgb(54 23 2 / 33%) 6.2%)",
                height: "450px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "13px",
              }}
              data-aos="fade-up"
            >
              <img
                src={sayahat}
                style={{ height: "270px" }}
                alt=""
                className="project_image"
              />
              <img
                src={sayahat1}
                style={{ height: "270px" }}
                alt=""
                className="project_image"
              />
            </Col>
          </Row>
          <Container>
            <TechContactForm />
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default Portfolio;
