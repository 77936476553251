import { Button, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import HashLoader from "react-spinners/HashLoader";
import { Link } from "react-router-dom";
import "./native.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import TransformIcon from "@mui/icons-material/Transform";

import TechContactForm from "../TechContactForm/TechContactForm";
import Footer from "../Footer/Footer";
import DevicesIcon from "@mui/icons-material/Devices";
import SpeedIcon from "@mui/icons-material/Speed";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "../Navbar/Navbar";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Native = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
    <Navbar />
      {loading ? (
        <div style={{
          backgroundColor:'black',
          height:'100vh'
        }}>
        <HashLoader
          color={`#F37A24`}
          loading={loading}
          size={80}
          style={{
            position: "relative",
            left: "50%",
            top: " 0px",
            zIndex: "10000",
            marginTop: "340px",
            borderImage: "initial",
            width: "30px",
            height: "30px",
            borderRadius: "100%",
            display: "inline-block",
            animation:
              "0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip",
          }}
        />
        </div>

      ) : (
        <>
          <div className="bgn-image"></div>
          <Container style={{ color: "white" }}>
            <h3 variant="h3" className="headingN">
              React Native Developement
            </h3>
            <p className="paragraphN1">
              Prepare to build dependable and high-performing mobile
              applications with  a reputable React Native development
              business.
            </p>

            <Link
              to="/ContactUs"
              style={{ textDecoration: "none" }}
              className="native-btn"
            >
              <Button
                className="native-btn2"
                style={{ color: "white" }}
                variant="outlined"
              >
                Hire React Native Developer
              </Button>{" "}
            </Link>
          </Container>

          <Container>
            {/* -----------------------Breadcrumb-start */}
            <div className="my-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    React Native
                  </li>
                </ol>
              </nav>
            </div>
            {/* -----------------------Breadcrumb-end */}

            <h3 className="my-5" style={{ textAlign: "center" }}>
              Our Features
            </h3>

            {/* --------------------------------------START */}
            <Container>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container style={{ textAlign: "center" }}>
                  <Grid item xs={12} md={3}>
                    <img
                      src="https://mmcgbl-1cc8f.kxcdn.com/wp-content/webp-express/webp-images/uploads/2021/11/satisfaction.png.webp"
                      style={{
                        filter:
                          "brightness(98%) contrast(106%) saturate(71%) blur(0px) hue-rotate(45deg)",
                        color: "purple",
                      }}
                      alt=""
                    />
                    <p>100% Client Satisfaction</p>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <img
                      src="https://mmcgbl-1cc8f.kxcdn.com/wp-content/webp-express/webp-images/uploads/2021/11/web-design.png.webp"
                      style={{
                        filter:
                          "brightness(98%) contrast(106%) saturate(71%) blur(0px) hue-rotate(45deg)",
                        color: "purple",
                      }}
                      alt=""
                    />
                    <p>Distinctive Design & Intuitive</p>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <img
                      src="https://mmcgbl-1cc8f.kxcdn.com/wp-content/webp-express/webp-images/uploads/2021/11/on-time.png.webp"
                      style={{
                        filter:
                          "brightness(98%) contrast(106%) saturate(71%) blur(0px) hue-rotate(45deg)",
                        color: "purple",
                      }}
                      alt=""
                    />
                    <p>On-Time Project Delivery</p>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <img
                      src="https://mmcgbl-1cc8f.kxcdn.com/wp-content/webp-express/webp-images/uploads/2021/09/chat-support.png.webp"
                      style={{
                        filter:
                          "brightness(164%) contrast(167%) saturate(117%) blur(0px) hue-rotate(360deg) hue-rotate(45deg)",
                        color: "purple",
                      }}
                      alt=""
                    />
                    <p>9am-6pm Customer Support</p>
                  </Grid>
                </Grid>
              </Box>
            </Container>

            {/* --------------------------------------END */}

            <h3 className="mt-5" style={{ textAlign: "center" }}>
              Why Choose Us For React Native Consulting?
            </h3>
            <p className="my-5" style={{ textAlign: "center" }}>
              In order to give our customers yet another quick and affordable
              choice for their mobile app projects, Codiishh launched the react
              native development services. A further benefit of this technology
              is that it enables the use of a single team for both online and
              mobile applications, greatly enhancing cost effectiveness.
            </p>

            <Box sx={{ flexGrow: 1 }} data-aos="fade-down">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Item>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar style={{ color: "purple" }}>
                            <i className="bi bi-speedometer2"></i>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Optimal Performance" />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar style={{ color: "purple" }}>
                            <TransformIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Reusable Codes" />
                      </ListItem>
                    </List>
                  </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Item>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar style={{ color: "purple" }}>
                            <DevicesIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="  Multi-platform development" />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar style={{ color: "purple" }}>
                            <i class="bi bi-broadcast"></i>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="live reload & open source" />
                      </ListItem>
                    </List>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={{ border: "2px solid red" }} md={4}>
                  <Item>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar style={{ color: "purple" }}>
                            <i class="bi bi-gear"></i>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Great Developer Tools" />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar style={{ color: "purple" }}>
                            <SpeedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Fastest app Speed" />
                      </ListItem>
                    </List>
                  </Item>
                </Grid>
              </Grid>
            </Box>

            <h3 className="my-5" style={{ textAlign: "center" }}>
              Multiple React Native Development
            </h3>
            <Box data-aos="fade-down">
              <Row>
                <Col xs={12} md={6} style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg,#202771 0%,#2D9DE0 100%)",
                      padding: "38px",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Server Side Mobile APIs
                    </h4>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Our software developers offer safe and secure server-side
                      APIs to help mobile applications reach their full
                      potential.
                    </span>
                  </div>
                </Col>

                <Col xs={12} md={6} style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg,#202771 0%,#2D9DE0 100%)",
                      padding: "38px",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      React Native iOS App Development
                    </h4>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Improve your company's productivity with our top-notch iOS
                      app, which explores various attractive react native ideas.
                    </span>
                  </div>
                </Col>
              </Row>
            </Box>

            <Box data-aos="fade-down">
              <Row>
                <Col xs={12} md={6} style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg,#202771 0%,#2D9DE0 100%)",
                      padding: "38px",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      React Native Android App Development
                    </h4>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      If you prefer Android devices, we offer a solution for
                      you: react native Android app development.
                    </span>
                  </div>
                </Col>

                <Col xs={12} md={6} style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg,#202771 0%,#2D9DE0 100%)",
                      paddingBottom: "45px",
                      paddingTop: "31px",
                      paddingLeft: "34px",
                      paddingRight: "34px",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Custom React Native App Development
                    </h4>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "300",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Custom app development based on industry requirements. Our
                      top-tier developers are extremely calm when it comes to
                      dealing with complicated complexities.
                    </span>
                  </div>
                </Col>
              </Row>
            </Box>

            {/* =======================END================ */}

            <TechContactForm />
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default Native;
