import React, { useEffect, useState } from "react";
import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Nodejs.css";
import LanguageIcon from "@mui/icons-material/Language";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Footer from "../Footer/Footer";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AddTaskIcon from "@mui/icons-material/AddTask";
import LoopIcon from "@mui/icons-material/Loop";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import TechContactForm from "../TechContactForm/TechContactForm";
import HashLoader from "react-spinners/HashLoader";
import Navbar from "../Navbar/Navbar";

const NodeJs = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
    <Navbar />
      {loading ? (
        <div
          style={{
            backgroundColor: "black",
            height: "100vh",
          }}
        >
          <HashLoader
            color={`#F37A24`}
            loading={loading}
            size={80}
            style={{
              position: "relative",
              left: "50%",
              top: " 0px",
              zIndex: "10000",
              marginTop: "340px",
              borderImage: "initial",
              width: "30px",
              height: "30px",
              borderRadius: "100%",
              display: "inline-block",
              animation:
                "0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip",
            }}
          />
        </div>
      ) : (
        <>
          <div className="bgg-image"></div>
          <div>
            <Container>
              <h3 variant="h3" className="headingNode">
                Node.js Development
              </h3>
              <p className="paragraphNode">
                Building large-scale, real-time, scalable web and mobile apps
                using JavaScript
              </p>
              {/* <p className="paragraph2">
        Hire our experienced React.js developers, and they will create an
        awesome <br /> solution and make it the best in the industry.
      </p> */}
              <Link
                to="/ContactUs"
                style={{ textDecoration: "none" }}
                className="node-btn"
              >
                <Button
                  className="node-btn2"
                  // style={{
                  //   position: "absolute",
                  //   top: "455px",
                  //   color: "white",
                  //   fontFamily: "cursive",
                  //   background: "black",
                  // }}
                  variant="outlined"
                  style={{ color: "white" }}
                >
                  Hire Our Node.Js developers
                </Button>{" "}
              </Link>
              <div className="my-5">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      NodeJS
                    </li>
                  </ol>
                </nav>
              </div>
              <div md={6}>
                <Container md={6}>
                  <Row>
                    <Col md={12} xs={12} className="textnode">
                      <p>
                        Node.js is an open-source, cross-platform backend
                        technology for building highly-scalable, data-intensive,
                        real-time web applications. It uses an event-driven,
                        non-blocking I/O model and enables the development of
                        apps with fast backend. Node.js gives great
                        opportunities to developers and increases the efficiency
                        of the engineering teams.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* <div>
          <Container>
        </div> */}

              <Row>
                <Col md={12}>
                  <h2 className="text-center pt-5 mt-4">
                    Why Consider Node.js For Your Web App
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p className="text-center  mt-4">
                    Software development with Node.js is a fast way to build
                    lightweight highly efficient products for both startups and
                    big companies.
                  </p>
                </Col>
              </Row>
            </Container>

            <Container>
              <Row className="First-row pt-5 mt-4" data-aos="fade-down">
                <Col md={4}>
                  <LanguageIcon style={{ fontSize: "51px", color: "purple" }} />

                  <p>Open source technology</p>
                </Col>

                <Col md={4}>
                  <CloudDownloadIcon
                    style={{ fontSize: "51px", color: "purple" }}
                  />
                  <p>Lightweight programming language</p>
                </Col>
                <Col md={4}>
                  <HeatPumpIcon style={{ fontSize: "51px", color: "purple" }} />
                  <p>Powered by Google’s V8 JS engine</p>
                </Col>
              </Row>
            </Container>
            {/* <.......................................................> */}
            <div>
              <Container>
                <Row className="First-row pt-5 mt-4" data-aos="fade-down">
                  <Col md={4}>
                    <i
                      className="bi bi-file-earmark-code"
                      style={{ fontSize: "51px", color: "purple" }}
                    ></i>

                    <p>Easily reusable modules</p>
                  </Col>

                  <Col md={4}>
                    <i
                      class="bi bi-server"
                      style={{ fontSize: "51px", color: "purple" }}
                    ></i>
                    <p>Compatible with both browser & server</p>
                  </Col>
                  <Col md={4}>
                    <i
                      class="bi bi-hdd-network-fill"
                      style={{ fontSize: "51px", color: "purple" }}
                    ></i>
                    <p>Event-driven architecture</p>
                  </Col>
                </Row>
              </Container>
            </div>
            {/* <........................................................> */}

            <div className="bgimgnode">
              {/* <img src={bgimg} alt="" srcset="" /> */}

              <Container fluid="md" style={{ color: "white" }}>
                <Row className="First-row mt-5 pt-5">
                  <Col>
                    <h2>Benefits of Node.js development for Business</h2>
                    <p className="pt-5 node-para">
                      Any business, from startups and SMBs to Fortune 500
                      companies, can benefit from the use of Node.js, which
                      allows creating fast and scalable web apps, such as Uber,
                      GoDaddy and has also become the de facto standard at Dow
                      Jones.
                    </p>

                    <ul className="node-uls">
                      <li data-aos="fade-right" className="node-lists">
                        <p>
                          <i className="bi bi-check"></i>
                          <strong>
                            Increases development team productivity
                          </strong>{" "}
                          thanks to the availability of a variety of NPM mod
                        </p>
                      </li>
                      <li data-aos="fade-right" className="node-lists">
                        <p>
                          <i className="bi bi-check"></i>
                          <strong>Reduces costs for development.</strong> It is
                          highly-scalable and requires fewer resources to handle
                          big loads as compared with other technologies.
                        </p>
                      </li>
                      <li data-aos="fade-right">
                        <p>
                          <i className="bi bi-check"></i>
                          <strong>Speeds time-to-market.</strong>It is fast and
                          speeds up the development process, increasing the
                          speed of any framework it is used with.
                        </p>
                      </li>
                      <li data-aos="fade-right">
                        <p>
                          <i className="bi bi-check"></i>
                          <strong>Sustainable.</strong>It allows building an app
                          that handles numerous client applications and still
                          works faultlessly.
                        </p>
                      </li>
                      <li data-aos="fade-right">
                        <p>
                          <i className="bi bi-check"></i>
                          <strong>Efficient</strong> It uses Javascript so that
                          the same language can be used both on the backend and
                          on the frontend. As a result, no language boundaries
                          between front-end and backend development.
                        </p>
                      </li>
                      <li className="pb-5" data-aos="fade-right">
                        <p>
                          <i className="bi bi-check"></i>
                          <strong>Easy to maintain.</strong>There is no need to
                          hire separate developers for further backend as well
                          as front-end maintenance.
                        </p>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>

            {/* <.................................................> */}

            <div className="pt-5">
              <Container>
                <Row>
                  <Col md={12}>
                    <h2 className="text-center">
                      Node.JS Services Offered by Coddish
                    </h2>
                  </Col>
                </Row>
                <Row
                  className="pt-3 mt-3"
                  style={{
                    textAlign: "center",

                    justifyContent: " center",
                  }}
                  data-aos="fade-down"
                >
                  <Col md={2}>
                    <i
                      className="bi bi-pc-display-horizontal"
                      style={{ fontSize: "64px", color: "purple" }}
                    ></i>
                    <p>API Development and Integration</p>
                  </Col>
                  <Col md={2}>
                    <i
                      className="bi bi-hdd-network"
                      style={{ fontSize: "64px", color: "purple" }}
                    ></i>
                    <p>
                      Client-side development with such server side technologies
                      as Node.js, ASP.NET, PHP, etc
                    </p>
                  </Col>
                  <Col md={2}>
                    <i
                      className="bi bi-hdd"
                      style={{ fontSize: "64px", color: "purple" }}
                    ></i>
                    <p>
                      Node.js development using SQL/NoSQL (MongoDB, Redis,
                      CouchDB) databases, WebSockets and Phantom.js
                    </p>
                  </Col>
                  <Col md={2}>
                    <i
                      class="bi bi-laptop"
                      style={{ fontSize: "64px", color: "purple" }}
                    ></i>
                    <p> Web and Mobile Application UI/UX Development</p>
                  </Col>
                  <Col md={2}>
                    <i
                      class="bi bi-plugin"
                      style={{ fontSize: "64px", color: "purple" }}
                    ></i>
                    <p> Plug-ins Development</p>
                  </Col>
                </Row>
              </Container>
            </div>

            {/* <<<<<<<<<<<<<<<<<<<<<<<<>....................................</> */}

            <div
              className="imgtext text-center pt-5 mt-5"
              style={{ color: "white" }}
            >
              <Container>
                <Row data-aos="fade-right">
                  <Col md={12} className="text-center">
                    <h4
                      style={{
                        fontSize: "43px",
                        fontWeight: "300",
                      }}
                    >
                      What Apps Can Be Build with Node.js
                    </h4>

                    <h6>
                      Node.js is appropriate for creating real-time
                      applications, including:
                    </h6>
                  </Col>
                </Row>

                <div className="rowleft">
                  <Row
                    className="beneRow"
                    style={{ justifyContent: "center" }}
                    data-aos="fade-right"
                  >
                    <Col
                      style={{ display: "none" }}
                      md={6}
                      xs={12}
                      className="pt-5"
                    >
                      <ul
                        style={{
                          listStyle: "none",
                          padding: "20px",
                          lineHeight: "43px",
                          textAlign: "justify",
                          fontSize: "15px",
                        }}
                      >
                        <li>
                          <i className="bi bi-arrow-right"></i> Chat apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Games
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Image processing
                          apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Big data
                          manipulation apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Field service
                          apps{" "}
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Data storage
                          apps{" "}
                        </li>
                      </ul>
                    </Col>

                    <Col md={6} xs={12}>
                      <ul
                        style={{
                          listStyle: "none",
                          padding: "20px",
                          lineHeight: "31px",
                          textAlign: "initial",
                          fontSize: "15px",
                        }}
                      >
                        <li>
                          <i className="bi bi-arrow-right"></i> Chat apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Social
                          networking apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Web portals
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Big data
                          manipulation apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Backend
                          dashboards{" "}
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Content
                          management apps{" "}
                        </li>
                      </ul>
                    </Col>
                    <Col md={4}>
                      <ul
                        style={{
                          listStyle: "none",
                          padding: "20px",
                          lineHeight: "31px",
                          textAlign: "initial",
                        }}
                      >
                        <li>
                          <i className="bi bi-arrow-right"></i> Workforce and
                          facility management apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Social
                          networking apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Web portals
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Big data
                          manipulation apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Backend
                          dashboards{" "}
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Content
                          management apps{" "}
                        </li>
                      </ul>
                    </Col>
                    <Col style={{ display: "none" }} md={2} className="pt-5">
                      <ul
                        style={{
                          listStyle: "none",
                          padding: "20px",
                          lineHeight: "43px",
                          // textAlign: "justify",
                        }}
                      >
                        <li>
                          <i class="bi bi-arrow-right"></i> Workforce and
                          facility management apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Social
                          networking apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Web portals
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Big dclassata
                          manipulation apps
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Backend
                          dashboards{" "}
                        </li>
                        <li>
                          <i className="bi bi-arrow-right"></i> Content
                          management apps{" "}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>

            {/* <............................................> */}

            <div className=" pt-5 mt-5 text-center">
              <Container>
                <Row>
                  <Col md={12}>
                    <h4
                      style={{
                        fontSize: "43px",
                        fontWeight: "300",
                      }}
                    >
                      Why Hire Node.js Developers from Coddish
                    </h4>
                  </Col>
                </Row>

                <Row
                  style={{
                    textAlign: "center",
                    marginTop: "50px",

                    justifyContent: " center",
                  }}
                  data-aos="fade-down"
                >
                  {/* <Col md={2}>
                <i
                  class="bi bi-pc-display-horizontal"
                  style={{ fontSize: "64px", color: "blue" }}
                ></i>
                <p>API Development and Integration</p>
              </Col> */}
                  <Col md={2}>
                    <IntegrationInstructionsIcon
                      style={{ fontSize: "64px", color: "purple" }}
                    />

                    <p>20+ Node.js developers</p>
                  </Col>
                  <Col md={2}>
                    <AddTaskIcon
                      style={{ fontSize: "64px", color: "purple" }}
                    />

                    <p>50+ completed Node.js projects</p>
                  </Col>
                  <Col md={2}>
                    <LoopIcon style={{ fontSize: "64px", color: "purple" }} />

                    <p> Full-cycle quality assurance</p>
                  </Col>
                  <Col md={2}>
                    <SettingsSuggestIcon
                      style={{ fontSize: "64px", color: "purple" }}
                    />

                    <p>After-sales support and maintenance</p>
                  </Col>
                </Row>
                <TechContactForm />
              </Container>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default NodeJs;
