import HashLoader from "react-spinners/HashLoader";
import { Link, useNavigate } from "react-router-dom";
import "./Contact.css";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { Formik, Form, Field, useField } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";
import axios from "axios";
import { BASE_URL } from "../helpers";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../Navbar/Navbar";
const Contact = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const MyTextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <textarea className="text-area" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };


  // let history = useHistory();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validate = Yup.object({
    fullName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("First Name is Required"),
    companyName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Company Name is Required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone is required"),
    // message: Yup.string().required("Message is required"),
    role: Yup.string().required("Role is required"),
  });
  return (
    <Formik
      initialValues={{
        fullName: "",
        companyName: "",
        email: "",
        phone: "",
        organisation: "",
        role: "",
        message: "",
      }}
      validationSchema={validate}
      onSubmit={(data) => {

        axios({
          method: "post",
          url: `${BASE_URL}/main-contact-form/save`,
          data: data,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        })
          .then(function (response) {
            console.log(response);
                toast.success('Data has been send successfully!!', {
                  position: "bottom-center",
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
                  setTimeout(() => {
                    navigate('/Contactus')
                  }, 4000);
          })
          .catch(function (response) {
            console.log(response);
          });
        // history.go(0);
      }}
    >
      {(formik) => (
        <>
        <Navbar />
          {loading ? (
            <div
              style={{
                backgroundColor: "black",
                height: "100vh",
              }}
            >
              <HashLoader
                color={`#F37A24`}
                loading={loading}
                size={80}
                style={{
                  position: "relative",
                  left: "50%",
                  top: " 0px",
                  zIndex: "10000",
                  marginTop: "340px",
                  borderImage: "initial",
                  width: "30px",
                  height: "30px",
                  borderRadius: "100%",
                  display: "inline-block",
                  animation:
                    "0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip",
                }}
              />
            </div>
          ) : (
            <>
              <div className="bgimage">
                <div className="contact-heading-div">
                  <h1 className="container contact-heading">Contact</h1>
                </div>
              </div>

              <h1 className="text-center mt-5">Let's Talk</h1>
              <div className="container">
                <div className="container form-top">
                  <div className="row">
                    <div className="col-md-12 col-md-offset-12 col-sm-12 col-xs-6 col-xl-6">
                      <h3 style={{ color: "black" }}>
                        <i className="fas fa-tty mr-4 mt-xl-5 pt-xl-5"></i>By
                        Phone
                      </h3>

                      <p style={{ color: "black" }} className="con-head">
                        Call us at:{" "}
                        <Link
                          style={{ color: "blue" }}
                          to="tel:+1 (805) 387-7606"
                        >
                          +1 (815) 219-1969
                        </Link>
                      </p>
                      <br />

                      <h3 style={{ color: "black" }}>
                        <i className="fas fa-paper-plane mr-4"></i>By Email
                      </h3>

                      <p style={{ color: "black" }} className="con-head">
                        Email us anytime at:{" "}
                        <Link
                          style={{ color: "blue" }}
                          // to="mailto:contactus@shelbeybrothers.com"
                        >
                          info.Coddish.com
                        </Link>
                      </p>
                      <br />
                      <h3 style={{ color: "black" }}>
                        <i className="fas fa-map-marker-alt mr-4"></i>Address
                      </h3>
                      <p style={{ color: "black" }} className="con-head">
                      512 Saint Charles Road Carol Stream , IL 60188
                      </p>
                    </div>
                    <div
                      className="col-md-12 mt-sm-5 col-md-offset-12 col-sm-12 col-xs-6 col-xl-6"
                      data-aos="fade-left"
                      style={{
                        boxShadow: "-1px -1px 30px 0px rgba(53, 50, 50, 0.75)",
                        backgroundColor: "white",
                        marginTop: "100px",
                        padding: " 27px 27px",
                      }}
                    >
                      <div className="panel panel-danger">
                        <div className="panel-body">
                          <Form className="col-md-">
                            <label for="FullName">
                              FullName <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="fullName"
                              type="text"
                              style={{
                                marginTop: "-13px",
                              }}
                            />
                            <label for="Company Name">
                              CompanyName{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              style={{
                                marginTop: "-13px",
                              }}
                              name="companyName"
                              type="text"
                            />
                            <label for="Email">
                              Email <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              style={{
                                marginTop: "-13px",
                              }}
                              name="email"
                              type="email"
                            />
                            <label for="Phone">
                              Phone <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              style={{
                                marginTop: "-13px",
                              }}
                              name="phone"
                              type="number"
                            />
                            <label for="Role Type" className="mb-2">
                              Role <span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <Field
                              as="select"
                              name="role"
                              style={{
                                backgroundColor: "#f0f0ff",
                                color: "rgb(108, 117, 125)",
                                width: "100%",
                                padding: "11px 4px",
                                outline: "none",
                                border: "1px solid #d7dce1",
                                borderRadius: " 7px",
                                validate,
                              }}
                            >
                              <option value="" disabled selected>
                                --Please Select Your Role--
                              </option>
                              <option value="C-level/SVP">C-level/SVP</option>
                              <option value="VP/Director">VP/Director</option>
                              <option value="Manager">Manager</option>
                              <option value="Individual Contributor">
                                Individual Contributor
                              </option>
                              <option value="Student/Intern">
                                Student/Intern
                              </option>
                              <option value="Others">Others</option>
                            </Field>

                          

                            <label for="Message" className="mt-3 mb-2">
                              Message <span></span>
                            </label>
                            <MyTextArea
                              name="message"
                              rows="4"
                              cols="59"
                              style={{
                                backgroundColor: "#f0f0ff",
                                border: "1px solid #d7dce1",
                                borderRadius: "7px",
                                padding: "1px 6px",
                                outline: "none",
                              }}
                            />
                            <TextField
                              label="Organization"
                              name="organisation"
                              type="text"
                            />

                            {/* <button
                className="btn btn-primary mt-3"
                type="submit"
                style={{ marginRight: 20 }}
              >
                Submit
              </button> */}
                            <Button
                              id="createProductBtn"
                              type="submit"
                              variant="contained"
                              color="primary"
                              // disabled={
                              //   !fullName ||
                              //   !companyName ||
                              //   !email ||
                              //   !phone ||
                              //   !role
                              // }
                              className="btn btn-raised btn-block btn-danger my-2"
                              // onClick={handleShow}
                            >
                              {/* Send */}
                              SEND &rarr;
                            </Button>
                          </Form>
                          <ToastContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Formik>
  );
};

export default Contact;
