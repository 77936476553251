import React from "react";
import "./Landing.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Gif from "../../src/assets/gif.gif";
import Gif1 from "../../src/assets/1.gif";
import { CarouselComp } from "../CarouselComp/CarouselComp";
import { useNavigate } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();
  const handleRoute = ()=>{
    navigate("/Aboutus")
  }
  return (
    <>
      <div className="bg-services">
        <Container className="text-center text-justify">
          <Row>
            <Col md={12} xs={12} lg={6} className="mt-5">
              <h1>WHO WE ARE?</h1>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12} lg={6}>
              <p className="text1" style={{fontSize:'16px'}}>
                "Coddish has long been renowned for its wide range of offerings.
                We have been providing standardised services to our clientele.
                The realisation of your dreams is the only thing we have in
                mind. Coddish and its master team are of the opinion that the
                only truly excellent service is one that can keep a client for
                an extended period of time. We are experts in every recognisable
                area of information technology, and we continue to advance
                daily. By delivering high-quality software solutions that are
                specifically built to meet client needs, we pledge to provide
                competitive values to our clients' projects.
              </p>
              <Button
                className="text-start bg-gradient"
                variant="outline-secondary"
                onClick={handleRoute}
              >
                <i class="bi bi-arrow-right"></i> know More About Us
              </Button>
            </Col>
            <Col md={12} xs={12} lg={6}>
              <img
                className="gif"
                style={{ width: "500px", marginTop: "-10px" }}
                src={Gif}
                alt=""
              />
            </Col>
          </Row>

          <Container className="text-center text-justify mt-5">
            <Row>
              <Col className="orderimg" md={12} xs={12} lg={6}>
                <img
                  className="gif1 gif mt-5 mb-4"
                  style={{ width: "500px" }}
                  src={Gif1}
                  alt=""
                />
              </Col>
              <Col
                md={12}
                lg={6}
                xs={{ span: 12, order: 1 }}
                data-aos="fade-down"
                data-aos-offset="90"
                style={{marginTop:'90px'}}
              >
                <h1>WHAT CODDISH DO?</h1>
                <Col>
                  <p className="text1" style={{fontSize:'16px'}}>
                    We are renowned for having one of the best and most vibrant
                    work environments, and up until now, we have successfully
                    offered our clients with a variety of services. We receive
                    all IT services at Coddish Technologies, which you may refer
                    to as a one-stop shop. Web, software, and mobile app
                    development are all part of Coddish Services. Being
                    innovators in the IT sector, we first emerged as SEO
                    experts. Additionally, our experts can assist you with web
                    hosting, digital marketing, and web design.
                  </p>
                </Col>
              </Col>
            </Row>
          </Container>

          <Row>
            <Col md={12} xs={12} lg={12} className="my-5">
              <h1>OUR SERVICES</h1>
            </Col>
            <CarouselComp />
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Landing;
