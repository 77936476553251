import React, { useState } from "react";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import axios from "axios";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "./TechContactForm.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../helpers";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  
const TechContactForm = ({ history }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    // navigate(0);
  };
  const handleShow = () => {
    if (!isEmail(values.email)) {
      setShow(false);
    } else if (isEmail(values.email)) {
      setShow(true);
    }
  };
  const navigate = useNavigate();
  const isEmail = (email) =>
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
      email
    );

  const [values, setValues] = useState({ email: "" });
  const [errors, setErrors] = useState({});
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  // const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [contactImage, setContactImage] = useState("");

  const onChangeFile = (e) => {
    setContactImage(e.target.files[0]);
  };

  const createProductSubmitHandler = (e) => {
    e.preventDefault();
    const myForm = new FormData();

    myForm.set("fullName", fullName);

    myForm.set("companyName", companyName);

    const errors = {};

    if (!isEmail(values.email)) {
      errors.email = "Invalid email!";
    } else if (isEmail(values.email)) {
      myForm.set("email", values.email);
    }
    setErrors(errors);

    myForm.set("phone", phone);

    myForm.set("message", message);

    myForm.set("contactImage", contactImage);
    axios.post(`${BASE_URL}/contact/add`, myForm).then(function (response) {
      console.log(response);
      toast.success('Data has been send successfully!!', {
        position: "bottom-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        setTimeout(() => {
          navigate('/Contactus')
        }, 4000);
    }).catch(function (response) {
      console.log(response);
    });;
  };
  const setEmail = (e) => {
    setValues((values) => ({ ...values, email: e.target.value }));
  };
  return (
    <div
      className="container text-center mt-5"
      style={{
        boxShadow: "10px 10px 39px 0px rgba(0,0,0,0.75)",
        webkitBoxShadow: "10px 10px 39px 0px rgba(0,0,0,0.75)",
        mozBoxShadow: "10px 10px 39px 0px rgba(0,0,0,0.75)",
        borderRadius: "10px",
        padding: "30px 0px",
      }}
    >
      <h4 style={{ color: "black" }} className="my-3">
      Complete the form to receive a free consultation from our experts.
      </h4>
      <h5 style={{ color: "black" }} className="mt-3">
      We welcome any inquiries or requests you may have.
      </h5>
      <form
        encType="multipart/form-data"
        onSubmit={createProductSubmitHandler}
        className="col-md-6 mt-5"
        style={{ margin: "0 auto" }}
      >
        <div className="my-3 inputField">
          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
        <div className="my-3 inputField">
          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className="my-3 inputField">
          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Email"
            value={values.email}
            onChange={setEmail}
          />
        </div>
        {Object.entries(errors).map(([key, error]) => (
          <span
            key={`${key}: ${error}`}
            style={{
              fontWeight: "bold",
              color: "red",
              display: "flex",
              marginLeft: "7px",
            }}
          >
            {error}
          </span>
        ))}
        <div className="my-3 inputField">
          {/* <label>
            <i className="fa fa-phone" aria-hidden="true"></i> Phone
            <span style={{ color: "red" }}>*</span>
          </label> */}
          <TextField
            fullWidth
            type="number"
            required
            id="outlined-required"
            label="Phone no"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {/* <PhoneInput
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={setPhone}
                          /> */}
        </div>
        <div className="my-3 inputField">
          <TextareaAutosize
            required
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="messageArea"
          />
        </div>

        <div id="createProductFormFile" className="mb-3 inputField">
          <TextField
            id="outlined-basic"
            required
            variant="outlined"
            type="file"
            onChange={onChangeFile}
            name="productVacationPicture"
            inputProps={{
              multiple: false,
            }}
            style={{ width: "100%" }}
          />
        </div>

        <Button
          id="createProductBtn"
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            !fullName ||
            !companyName ||
            !values.email ||
            !phone ||
            !message ||
            !contactImage
          }
          className="send-button"
          onClick={handleShow}
        >
          {/* Send */}
          <div class="alt-send-button">
            <i class="fa fa-paper-plane"></i>
            <span class="send-text">SEND</span>
          </div>
        </Button>
      </form>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} style={{ marginTop: "100px" }}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Data has been Sent SUCCESSFULLY!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TechContactForm;
