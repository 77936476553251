import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Aboutus.css";
import TechContactForm from "../TechContactForm/TechContactForm";
import Footer from "../Footer/Footer";
import HashLoader from "react-spinners/HashLoader";
import hqimg from "../assets/hq.svg";
import ee from "../assets/ee.jpg"
import FastDelivery from "../assets/FastDelivery.svg"
import Navbar from "../Navbar/Navbar";

export const Aboutus = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
    <Navbar />
      {loading ? (
        <div style={{
          backgroundColor:'black',
          height:'100vh'
        }}>
        <HashLoader
          color={`#F37A24`}
          loading={loading}
          size={80}
          style={{
            position: "relative",
            left: "50%",
            top: " 0px",
            zIndex: "10000",
            marginTop: "340px",
            borderImage: "initial",
            width: "30px",
            height: "30px",
            borderRadius: "100%",
            display: "inline-block",
            animation:
            "0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip",
          }}
          />
          </div>
      ) : (
        <>
          <div className="bga-image">
            <Container>
              <h3 variant="h3" className="heading-about">
                About Us
              </h3>
              <p className="paragraph-about">
                A forward-thinking US-based software development company,
                <br /> CODDISH. We harness the power of innovation to provide
                cutting-edge solutions for companies all over the world.
                <br />
                We ensure your tech success.
              </p>
            </Container>
          </div>

          <Container>
            {/* -----------------------Breadcrumb-start */}
            <div className="my-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About Us
                  </li>
                </ol>
              </nav>
            </div>
            {/* -----------------------Breadcrumb-end */}
          </Container>

          <Container>
            <div className="text-center">
              <h3>Start Your Success Journey With Us</h3>
              <p style={{ fontSize: "15px", paddingTop: "30px" }}>
                Our skilled engineering team assists funded startups and <br />
                SME businesses in achieving their lofty objectives.
              </p>
            </div>
          </Container>

          {/* <------------------location start-------------------> */}
          <Container className="pt-5 text-center">
            <Row>
              <Col md={6}>
                <h3>Based in the US</h3>

                <p style={{ fontSize: "15px", paddingTop: "30px" }}>
                  One of the most well-known IT hubs, Chicago, Illinois, is
                  where we have our headquarters. Due to our team's close
                  closeness to our clients, you may always get in touch with us
                  in a method that suits you.
                </p>
              </Col>

              <Col md={6} data-aos="fade-left">
                <div>
                  <img src={hqimg} alt="location" 
                    style={{filter: 'brightness(1.1) hue-rotate(183deg)'}}
                  
                  />
                </div>
              </Col>
            </Row>
          </Container>
          {/* <----------location end------------------------> */}

          {/* <--------------------------------expertise start----------------------> */}
          <Container className="pt-5 text-center" style={{ marginTop: "50px" }}>
            <Row>
              <Col md={6} className="orderimgg" data-aos="fade-right">
                <div>
                  <img
                    src={ee}
                    alt="location"
                    style={{width:'50%',filter: 'brightness(1.1) hue-rotate(183deg)'}}
                  />
                </div>
              </Col>

              <Col md={6} className="mt-5">
                <h3>Expertise</h3>
                <p style={{ fontSize: "15px", paddingTop: "30px" }}>
                  We have highly skilled tech specialists on board, so we are
                  well familiar with the features that go into a successful
                  product. Our development staff assists you at every level and
                  aids in the release of your software or app to the market.
                </p>
              </Col>
            </Row>
          </Container>

          {/* <--------------------------------expertise end----------------------> */}

          {/* <------------------delivery start-------------------> */}
          <Container
            className="pt-5 text-center"
            style={{ marginTop: "50px", paddingBottom: "30px" }}
          >
            <Row>
              <Col md={6}>
                <h3>Fast Delivery</h3>

                <p style={{ fontSize: "15px", paddingTop: "30px" }}>
                  According to our data, we make deliveries 30–50% more quickly
                  than competing providers. As a key competitive advantage in
                  the current digital environment, we constantly work to reduce
                  your Time to Market.
                </p>
              </Col>

              <Col md={6} data-aos="fade-left">
                <div>
                  <img
                    src={FastDelivery}
                    alt="location"
                    style={{filter: 'brightness(1.1) hue-rotate(183deg)'}}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          {/* <-------------------delivery end---------------> */}

          {/* <________________________OUR VALUE start-----------------------> */}

          {/* <div
            style={{
              backgroundColor: "black",
              color: "white",
              paddingBottom: "20px",
            }}
          >
            <Row>
              <Col className="text-center">
                <h3 style={{ paddingTop: "40px" }}>Our Values</h3>
              </Col>
            </Row>

            <div style={{ paddingTop: "90px" }}>
              <Row>
                <Col md={1} xs={12} className="text-center">
                  <img
                    src="https://cdn-clekk.nitrocdn.com/tkvYXMZryjYrSVhxKeFTeXElceKUYHeV/assets/static/optimized/rev-af6eeb1/wp-content/uploads/2021/03/communicationn.svg"
                    alt="Communication"
                    className="values-img"

                  />
                </Col>
                <Col md={5} xs={12} data-aos="fade-right">
                  <p className='values-para'>
                    Transparent and trustworthy communication lies in the core
                    of our values and serves as a cornerstone of our
                    collaboration
                  </p>
                </Col>

                <Col md={1} xs={12} className="text-center">
                  <img
                    src="https://cdn-clekk.nitrocdn.com/tkvYXMZryjYrSVhxKeFTeXElceKUYHeV/assets/static/optimized/rev-af6eeb1/wp-content/uploads/2021/03/values-c.svg"
                    alt="Communication"
                    className="values-img"

                  />
                </Col>
                <Col md={5} xs={12} data-aos="fade-right">
                  <p className='values-para'>
                    We are constantly on the same page with you since we share
                    the same values as our clients and communicate in the same
                    language.
                  </p>
                </Col>
              </Row>
            </div>

            <div style={{ paddingTop: "90px" }}>
              <Row>
                <Col md={1} xs={12} className="text-center">
                  <img
                    src="https://cdn-clekk.nitrocdn.com/tkvYXMZryjYrSVhxKeFTeXElceKUYHeV/assets/static/optimized/rev-af6eeb1/wp-content/uploads/2021/03/support-c.svg"
                    alt="Communication"
                    className="values-img"

                  />
                </Col>
                <Col md={5} xs={12} data-aos="fade-left">
                  <p className='values-para'>
                    Our customer service staff is available around-the-clock. We
                    are always available to answer your request, no matter where
                    in the world you are located.
                  </p>
                </Col>

                <Col md={1} xs={12} className="text-center">
                  <img
                    src="https://cdn-clekk.nitrocdn.com/tkvYXMZryjYrSVhxKeFTeXElceKUYHeV/assets/static/optimized/rev-af6eeb1/wp-content/uploads/2021/03/success-a.svg"
                    alt="Communication"
                    className="values-img"
                  />
                </Col>
                <Col md={5} xs={12} data-aos="fade-right">
                  <p className='values-para'>
                    We are more than just a business that offers top-notch
                    development services. We always go above and above to
                    satisfy your needs since we view your success as our own.
                  </p>
                </Col>
              </Row>
            </div>
          </div> */}


          <TechContactForm />
          <Footer />
        </>
      )}
    </>
  );
};
