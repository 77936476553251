import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import weblogo from "../../src/assets/logo/web dev logo.jpg";
import dmlogo from "../../src/assets/logo/dmlogo.jpg";
import "./CarouselComp.css";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
export const CarouselComp = () => {
  return (
    <>
      <Carousel
        swipeable={true}
        draggable={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // showDots={true}
        // ssr={true} // means to render carousel on server-side.
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        // keyBoardControl={true}
        // customTransition="all .5"
        // transitionDuration={500}
        // containerClass="carousel-container"
        // deviceType={this.props.deviceType}
        // dotListClass="custom-dot-list-style"
        // itemClass="carousel-item-padding-40-px"
      >
        <div className="services">
          <h5>01</h5>
          <h6>Software Development</h6>
          <img
            src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg"
            height="120px"
            alt=""
          />
          <p
            style={{
              textAlign: "justify",
              color: "black",
              marginTop: "9px",
            }}
          >
            In order to satisfy the business requirements of verticals, coddish,
            a software development firm, develops custom software. Throughout
            all stages of the product development process, we offer full-cycle
            software development services with QA testing.
          </p>
        </div>
        <div className="services">
          <h5>02</h5>
          <h6>Web Development</h6>
          <img src={weblogo} height="120px" alt="" />
          <p
            style={{
              textAlign: "justify",
              color: "black",
              marginTop: "9px",
            }}
          >
            We have made strides in providing the best web development services.
            coddish skilled developers employ the most recent tools to provide
            customers with the services they need. Our wide-ranging portfolio
            amply demonstrates our strengths.
          </p>
        </div>
        <div className="services">
          <h5>03</h5>
          <h6>Mobile App Development</h6>
          <img
            src="https://static.thenounproject.com/png/1248972-200.png"
            // height="40%"
            height="120px"
            alt=""
          />
          <p
            style={{
              textAlign: "justify",
              color: "black",
              marginTop: "9px",
            }}
          >
            With the support of our dedicated development team, we create the
            most attractive and practical mobile applications, and there is no
            subject that we are not successfully tackling. Bringing our clients'
            ideas to life is the best service we can offer.
          </p>
        </div>
        <div className="services">
          <h5>04</h5>
          <h6>Digital Marketing</h6>
          <img src={dmlogo} height="120px" alt="" />
          <p
            style={{
              textAlign: "justify",
              color: "black",
              marginTop: "9px",
            }}
          >
            We are reaching the ideal customer thanks to the recommendations of
            our skilled marketers. Because search engines are so carefully
            crafted, we precisely optimize your websites to help you get the
            right kind of visitors. All different types of digital marketing
            services are successfully offered by coddish.
          </p>
        </div>
        <div className="services">
          <h5>05</h5>
          <h6>Graphic Desgining</h6>
          <img
            src="https://cdn.iconscout.com/icon/free/png-128/graphic-design-100-1171908.png"
            height="120px"
            alt=""
          />
          <p
            style={{
              textAlign: "justify",
              color: "black",
              marginTop: "9px",
            }}
          >
            We have talented graphic designers who can portray your ideas and
            shape them perfectly. To further illustrate your ideas, we use
            vibrant colours, engaging characters, and detailed illustrations.
            Each and every graphic is created to guarantee originality and
            inventiveness.
          </p>
        </div>
        <div className="services">
          <h5>06</h5>
          <h6>Hosting Services</h6>
          <img
            src="https://static.thenounproject.com/png/3486378-200.png"
            height="120px"
            alt=""
          />
          <p
            style={{
              textAlign: "justify",
              color: "black",
              marginTop: "9px",
            }}
          >
            Let us assist you in connecting to a large audience, expanding your
            reach, and making your websites accessible. Keeping up with the most
            recent technological advances allows us to offer the best hosting
            services. The services offered by coddish Shed are high-quality and
            competitively priced.
          </p>
        </div>
      </Carousel>
    </>
  );
};
