import React, { useEffect, useState } from "react";
import "./Wordpress.css";
import { Button, Container } from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import SecurityIcon from "@mui/icons-material/Security";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RecommendIcon from "@mui/icons-material/Recommend";
import ordpress from "../assets/WordPress.png";
import teamwork from "../assets/teamwork.jpg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import SupportIcon from "@mui/icons-material/Support";
import TechContactForm from "../TechContactForm/TechContactForm";
import Footer from "../Footer/Footer";
import HashLoader from "react-spinners/HashLoader";
import Navbar from "../Navbar/Navbar";

const Wordpress = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
    <Navbar />
      {loading ? (
        <div style={{
          backgroundColor:'black',
          height:'100vh'
        }}>

        <HashLoader
          color={`#F37A24`}
          loading={loading}
          size={80}
          style={{
            position: "relative",
            left: "50%",
            top: " 0px",
            zIndex: "10000",
            marginTop: "340px",
            borderImage: "initial",
            width: "30px",
            height: "30px",
            borderRadius: "100%",
            display: "inline-block",
            animation:
              "0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip",
          }}
        />
        </div>
      ) : (
        <>
          <div className="bggg-image"></div>
          <div>
            {" "}
            <Container>
              <h3 variant="h3" className="headingW">
                Wordpress Development
              </h3>
              <p className="paragraphW">
                It's time for your website to reflect that you have a profitable
                business and outstanding ideas. With our knowledge of WordPress,
                we can be of assistance here. The top WordPress design and
                development company, Coddish, can assist with all of your
                WordPress requirements.
              </p>
              {/* <p className="paragraph2">
        Hire our experienced React.js developers, and they will create an
        awesome <br /> solution and make it the best in the industry.
      </p> */}
              <Link to="/ContactUs" style={{ textDecoration: "none" }} className="wordpress-btn">
                <Button
                  className="wordpress-btn2"
                  // style={{
                  //   position: "absolute",
                  //   top: "455px",
                  //   color: "white",
                  //   fontFamily: "cursive",
                  //   background: "black",
                  // }}
                  variant="outlined"
                  style={{color:'white'}}
                >
                  Hire Our Wordpress developers
                </Button>{" "}
              </Link>
              <div className="my-5">
                <nav aria-label="breadcrumb">
                  <ol className=" pt-3 breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Wordpress
                    </li>
                  </ol>
                </nav>
              </div>

              {/* <div md={6}>
            <Container md={6}>
              <Row>
                <Col md={12} xs={12} className="textnode pt-5 mt-4">
                  <p>
                    Node.js is an open-source, cross-platform backend technology
                    for building highly-scalable, data-intensive, real-time web
                    applications. It uses an event-driven, non-blocking I/O
                    model and enables the development of apps with fast backend.
                    Node.js gives great opportunities to developers and
                    increases the efficiency of the engineering teams.
                  </p>
                </Col>
              </Row>
            </Container>
          </div> */}

              {/* <div>
          <Container>
        </div> */}

              <Row>
                <Col md={12}>
                  <h2 className="text-center pt-5 mt-4">
                    Our Website Services
                  </h2>
                </Col>
              </Row>

              <Container>
                <Row className="First-row pt-5 mt-4"  data-aos="fade-down">
                  <Col md={4}>
                    <ArticleIcon style={{ fontSize: "51px" , color :"purple" }} />

                    <p>DIGITAL STRATEGY</p>
                    <p>
                      On a challenging assignment, expert counsel is invaluable.
                      Let our specialists provide technical direction,
                      problem-solving skills, and infrastructure consultancy to
                      help you along the road. How may our Strategy Team be of
                      service to you?
                    </p>
                  </Col>

                  <Col md={4}>
                    <DesignServicesIcon style={{ fontSize: "51px" , color :"purple" }} />
                    <p>UI/UX DESIGN</p>
                    <p>
                      We don't provide custom design services for you; rather,
                      we work with you to create them. We don't only design
                      looks. We get to know you and assist you in elevating your
                      brand. Let's build a digital presence for your business
                      that converts. Discover our strategy.
                    </p>
                  </Col>
                  <Col md={4}>
                    <DeveloperModeIcon style={{ fontSize: "51px" , color :"purple" }} />
                    <p>CUSTOM DEVELOPMENT</p>
                    <p>
                      Secure, excellent code is only the beginning. We take into
                      account every tiny detail to ensure that your website is
                      complete. We employ cutting-edge techniques to constantly
                      improve the speed, security, and dependability of your
                      website.
                    </p>
                  </Col>
                </Row>
              </Container>

              {/* <------------------------------------------------------> */}

              <Container className="pb-5">
                <Row className="First-row pt-5 mt-4"  data-aos="fade-down">
                  <Col md={4}>
                    <SecurityIcon style={{ fontSize: "51px" , color :"purple" }} />

                    <p>PROFORMANCE & SECURITY</p>
                    <p>
                      Security and performance are two of a website's most
                      crucial components. We make sure every line of code on
                      your website is as secure and efficient as possible using
                      multi-level code audits.
                    </p>
                  </Col>

                  <Col md={4}>
                    <ContentCopyIcon style={{ fontSize: "51px" , color :"purple" }} />
                    <p>CONTENT MIGRATION</p>
                    <p>
                      Whether your material is stored in a database (MySQL, SQL
                      Server, Oracle—you name it!), a web application (through
                      an API), or an exported file, we can import it everything
                      into WordPress.
                    </p>
                  </Col>
                  <Col md={4}>
                    <RecommendIcon style={{ fontSize: "51px" , color :"purple" }} />
                    <p>SUPPORT & MAINTENANCE</p>
                    <p>
                      Never again be concerned about your WordPress website.
                      Your website will be updated, automated offsite backups
                      will be set up, external security checks will be
                      performed, and more by our support staff.
                    </p>
                  </Col>
                </Row>
              </Container>

              {/* <------------------------------------------------------> */}
            </Container>
          </div>

          <div className="py-5" style={{ backgroundColor: " black" }}>
            <Container style={{ color: "white ", textAlign: "center" }}>
              <Row>
                <Col md={6}>
                  <div>
                    <h1 className="pt-3">Best In Class WordPress Expertise</h1>

                    <p className="pt-3" style={{textAlign:'justify'}}>
                      <strong>Coddish is a full-service WordPress shop.</strong>
                      <br />
                      Success requires the appropriate plan, which must be
                      followed. Coddish sets the standard by designing and
                      creating cutting-edge web and mobile experiences with
                      WordPress. From strategy and planning through design and
                      development, as well as comprehensive data migration,
                      extensive API integrations, scalability, performance, and
                      long-term assistance and maintenance, we offer end-to-end
                      WordPress opportunities. We take pride in the fact that
                      every member of our team is constantly studying the best
                      ways to design WordPress websites that are quick, secure,
                      and reliable.
                    </p>
                    <p className="pt-3 pb-3" style={{textAlign:'justify'}}>
                      <strong>
                        WordPress is the perfect foundation for the web.
                      </strong>
                      <br />
                      The top WordPress design and development business,
                      coddish, can assist with all of your WordPress
                      requirements.
                    </p>
                  </div>
                </Col>

                <Col md={6} data-aos="fade-left" className="mt-1">
                  <img src={ordpress} className="wordpress-img" alt="Wordpress" />
                </Col>
              </Row>
            </Container>
          </div>

          <div>
            {/* <--------------------------> */}
            <Container className="pb-5">
              <Row className="First-row pt-5 mt-4"  data-aos="fade-down">
                <Col md={4}>
                  <IntegrationInstructionsIcon style={{ fontSize: "51px" , color :"purple" }} />

                  <p>MEET THE BUDDYPRESS PROS</p>
                  <p>
                    Use BuddyPress to its full potential to turn your WordPress
                    website into a potent social network. Your bespoke
                    BuddyPress-powered website can be designed and built with
                    the assistance of Coddish, a top BuddyPress development and
                    design firm.
                  </p>
                </Col>

                <Col md={4}>
                  <ShoppingCartIcon
                    style={{ fontSize: "51px", textAlign: "justify" , color :"purple" }}
                  />
                  <p>ECOMMERCE</p>
                  <p>
                    The best platform for starting an online business and
                    selling goods is WordPress. We understand the ins and outs
                    of creating an online sales platform because we have
                    established large-scale, business eCommerce solutions for
                    our clients. For their business, our typical client needs an
                    eCommerce store with excellent design and personalization.
                  </p>
                </Col>
                <Col md={4}>
                  <SupportIcon
                    style={{ fontSize: "51px", textAlign: "justify" , color :"purple" }}
                  />
                  <p>MAINTAIN YOUR INVESTMENT</p>
                  <p>
                    When your website goes live, the work isn't done. With
                    continuing support and maintenance services, Coddish can
                    assist your WordPress website. WordPress core upgrades,
                    plugin and theme updates, scheduled offsite backups, and
                    frequent security checks are all included in the services.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          {/* <-----------------------------------------> */}

          <div className="pt-5" style={{ backgroundColor: " black" }}>
            <Container style={{ color: "white ", textAlign: "center" }}>
              <Row>
                <Col md={6}>
                  <div>
                    <h1 className="pt-5">WordPress as a Headless CMS</h1>

                    <p className="pt-2" style={{textAlign:'justify'}}>
                      Your content library, which includes blog entries, static
                      text, and media, is connected to your primary website
                      platform by a headless content management system (Headless
                      CMS).
                    </p>
                    <p className="pt-2" style={{textAlign:'justify'}}>
                      With Headless WordPress, speed and security are built-in,
                      and you retain full control over your content and data
                      without ever having to worry about the costs and terms
                      associated with a SaaS CMS.
                    </p>
                  </div>
                </Col>

                <Col md={6}  data-aos="fade-left">
                  <img
                    className="mb-5 wordpress-img2"
                    style={{
                      height: "auto",
                      width: "100%",
                    }}
                    src={teamwork}
                    alt="Wordpress"
                  />
                </Col>
              </Row>
            </Container>

            {/* <TechContactForm /> */}
          </div>

          <div>
            <Container>
              <Row>
                <Col>
                  <TechContactForm />
                </Col>
              </Row>
            </Container>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default Wordpress;
