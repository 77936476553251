import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Container } from "@mui/system";
import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./ParticlesComp.css";

export const ParticlesComp = (props) => {
  // const heading1 = props.heading1;
  // const heading2 = props.heading2;
  // const btn = props.btn;
  const particlesInit = async (main) => {
    console.log(main);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = async (container) => {
    console.log(container);
  };
  return (
    <>
      <Particles
        id="tsparticles"
        className="parti"
        init={particlesInit}
        loaded={particlesLoaded}
        style={{ width: "100%",  }}
        options={{
          fullScreen: {
            enable: false,
            zIndex: 1,

          },
          background: {
            color: {
              value: "#0000",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 90,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
      <Container>
        <p
          className="heading-1"
        >
          {props.heading1} <br />{" "}
          <span style={{ backgroundColor: "rgb(255 0 0)" }} className="heading-2">{props.heading2}</span>
        </p>
        <Link to="/ContactUs" className='btn-1'>
          <Button
            variant="outlined"
            style={{color:'white'}}
          >
            {props.btn}
          </Button>{" "}
        </Link>
      </Container>
    </>
  );
};
