import React from "react";
import logo from "../../src/assets/logo/Logo.png";
import estimate from "../../src/assets/dissabled-right.png";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormModal from "../FormModal/FormModal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Footer.css";


const date = new Date();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 5,
};
const Footer = () => {
  const navigate = useNavigate();
  const handleRoute = ()=>{
    navigate("/Aboutus")
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div style={{ backgroundColor: "black", color: "white" }} className="mt-5">
      <Container>
        <Row>
          <Col md={4} xs={12}>
            <img className="logofooter " src={logo} alt="" />

            <h5 className="estimate">Get An Estimate</h5>

            <Link>
              {" "}
              <img onClick={handleOpen} src={estimate} alt="" />
            </Link>
            {/* <img onClick={handleOpen} src={estimate} alt="" /> */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2">
                  Estimate Your Project
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <FormModal />
                </Typography>
              </Box>
            </Modal>
          </Col>

          <Col
            md={4}
            xs={12}
            className="footerservices "
            style={{ marginTop: "20px" }}
          >
            <h5>Services</h5>
            <div className="list">
              <li>Website Development</li>
              <li>Mobile App Development</li>
              <li>SEO Services</li>
              <li>Digital Marketing</li>
              <li>Graphic Designing</li>
              <li>Web Hosting Provider</li>
            </div>
          </Col>

          <Col md={4} xs={12}>
            <h5 style={{ marginTop: "20px" }}>Contact</h5>
            <li className="conlist">
              <i className="bi bi-inbox"></i> info@coddish.com
            </li>
            <Button
              className="text-start bg-gradient mt-2"
              variant="outline-secondary"
              onClick={handleRoute}

            >
              <i className="bi bi-arrow-right"></i> know More About Us
            </Button>
          </Col>
        </Row>
        <Divider className="mt-5" variant="middle" />

        <li style={{ listStyle: "none" }} className="text-center mt-2">
          All Rights Reserved © Coddish {date.getFullYear()}
        </li>
      </Container>
    </div>
  );
};

export default Footer;
