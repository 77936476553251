import styled from "styled-components";

export const Heading = styled.h1`
  text-align: center;
  color: green;
`;

export const Content = styled.div`
  overflowy: scroll;
  height: 2500px;
`;

export const Button = styled.div`
  position: fixed;
  width: 100%;
  left: 94%;
  bottom: 60px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #800080;
  @media screen and (max-width: 500px){
   position: fixed;
   width: 100%;
   left: 83%;
   bottom: 72px;
   height: 27px;
   font-size: 2.7rem;
   z-index: 1;
   cursor: pointer;
   color: #800080;
  }
  @media (min-width: 550px) and (max-width: 900px){
   position: fixed;
   width: 100%;
   left: 90%;
   bottom: 75px;
   height: 27px;
   font-size: 2.7rem;
   z-index: 1;
   cursor: pointer;
   color: #800080;
  }
`;
