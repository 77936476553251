import "./App.css";
import React,{useState,useEffect} from "react";
import HashLoader from "react-spinners/HashLoader";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import Home from "./Pages/Home";
import ReactTech from "./Pages/ReactTech";
import Native from "./Pages/Native";
import NodeJs from "./Pages/NodeJs";
import Portfolio from "./Pages/Portfolio.js";
import Contact from "./Pages/Contact";
import Wordpress from "./Pages/Wordpress"
import { Aboutus } from "./Pages/Aboutus";
import TopBottom from "./TopBottom/TopBottom";

function App() {
  const [loading, setLoading] = useState(false);

useEffect(() => {
  setLoading(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);
}, []);

  return (    
        <BrowserRouter>
        {loading ? (
          <div style={{
            height: '100vh',
            backgroundColor: 'black',
          }}>
          <HashLoader 
          color={`#F37A24`}
          loading={loading}
          size={80}
          style={{
             position: "relative",
             left: "50%",
             top: " 0px",
             zIndex: "10000",
             marginTop: "340px",
             borderImage: "initial",
             width: "30px",
             height: "30px",
             borderRadius: "100%",
             display: "inline-block",
             animation:
               "0.75s linear 0s infinite normal both running react-spinners-ClipLoader-clip",
           }}
          />
          </div>
        ) :(
          <>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Reactjs" element={<ReactTech />} />
            <Route path="/ReactNative" element={<Native />} />
            <Route path="/NodeJs" element={<NodeJs />} />
            <Route path="/Portfolio" element={<Portfolio />} />
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/Contactus" element={<Contact />} />
            <Route path="/Wordpress" element={<Wordpress/>} />
          </Routes>
          <TopBottom/>
          </>
        )
      }
          {/* <Footer /> */}
        </BrowserRouter>
     
  );
}

export default App;
