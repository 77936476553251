import React from "react";
import Landing from "../Landing/Landing";
import { ParticlesComp } from "../Particles/ParticlesComp";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

const Home = () => {
  return (
    <>
    <Navbar />
      <ParticlesComp
        heading1="All Good Ideas Start with"
        heading2="Coddish Creation."
        btn="Let's Talk"
      />
      <Landing />
      <Footer />
    </>
  );
};

export default Home;
