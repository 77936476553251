import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../assets/logo/Logo.png";
import { useScrollTrigger } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Navbar.css";
import {FaBars} from 'react-icons/fa'

function ChangeColorOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    style: { background: trigger ? "black" : "transparent" },
  });
}

const Navbar = (props) => {
  const theme = useTheme();
  // console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  // console.log(isMatch);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible,setVisible] = useState(true);
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });


  return (
    <>
      <ChangeColorOnScroll {...props}>
        <div className="wrapper">
          <nav>
            <input type="checkbox" id="show-search" />
            <input type="checkbox" id="show-menu" />
            <label for="show-menu" className="menu-icon">
              {/* <i className="fas fa-bars"></i> */}
              <FaBars/>
            </label>
            <div className="content">
              <div className="logo">
                <Link to="/">
                  <img style={{ width: "264px" }} src={logo} alt="Coddish" />
                </Link>
              </div>
              <ul className="links" >
                <li>
                  <Link to="/Portfolio">Portfolio</Link>
                </li>
                <li>
                  <Link to="#" className="desktop-link">
                    Technologies
                  </Link>
                  <input type="checkbox" id="show-features" />
                  <label for="show-features">Technologies</label>
                  <ul>
                    <li>
                      <Link to="/Reactjs">Reactjs</Link>
                    </li>
                    <li>
                      <Link to="/ReactNative">ReactNative</Link>
                    </li>
                    <li>
                      <Link to="/NodeJs">NodeJs</Link>
                    </li>
                    <li>
                      <Link to="/Wordpress">Wordpress</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/Aboutus">About us</Link>
                </li>
                <li>
                  <Link to="/Contactus">Contact Us</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </ChangeColorOnScroll>
    </>
  );
};

export default Navbar;
